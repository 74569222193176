.team-member {
    margin-bottom: 3rem;
    text-align: center;
  }
  .team-member img {
    width: 14rem;
    height: 14rem;
    border: 0.5rem solid rgba(0, 0, 0, 0.1);
  }
  .team-member h4 {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }

  .page-section h2.section-heading {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

