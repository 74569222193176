.section-header h3 {
  font-size: 36px;
  color: #283d50;
  text-align: center;
  font-weight: 500;
  position: relative;
}

.section-header p {
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 60px;
  color: #556877;
  width: 50%;
}

#clients {
  padding: 60px 0;
}

#clients .clients-wrap {
  border-top: 1px solid #d6eaff;
  border-left: 1px solid #d6eaff;
  margin-bottom: 30px;
}

#clients .client-logo {
  padding: 64px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #d6eaff;
  border-bottom: 1px solid #d6eaff;
  overflow: hidden;
  background: #fff;
  height: 160px;
}

#clients img {
  transition: all 0.4s ease-in-out;
}
